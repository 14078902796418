@font-face {
  font-family: 'arial';
  src: url('./assets/fonts/arial/SVN-Arial-Regular.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'arial';
  src: url('./assets/fonts/arial/SVN-Arial-Bold.ttf');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: 'arial';
  src: url('./assets/fonts/arial/SVN-Arial-Bold.ttf');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'arial';
  src: url('./assets/fonts/arial/SVN-Arial-Bold.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'arial';
  src: url('./assets/fonts/arial/SVN-Arial-Italic.ttf');
  font-display: swap;
  font-style: italic;
}

* {
  font-family: var(--font_family);
  font-style: normal;
}
#root {
  background-color: var(--background_color_primary);
  color: var(--text_color_primary);
  min-height: 100vh;
}


